export default [
    {
        path: '/account-setting',
        name: 'account-setting',
        component: () => import('@/views/account/AccountSetting.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Mi Perfil',
            breadcrumb: [
                {
                    text: 'Configuraciones',
                    active: true
                }
            ],
            requiresAuth: true
        }
    }
]