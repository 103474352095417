export default [{
        path: '/notices',
        name: 'notices',
        component: () =>
            import ('@/views/modules/notices/notices-list/NoticesList.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Noticias',
            breadcrumb: [{
                text: 'Lista de noticias',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/notice/create',
        name: 'notices-create',
        component: () =>
            import ('@/views/modules/notices/notices-store/NoticeStore.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Noticias',
            breadcrumb: [{
                text: 'Nueva noticia',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/notice/:id/edit',
        name: 'notices-edit',
        component: () =>
            import ('@/views/modules/notices/notices-edit/NoticesEdit.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Noticias',
            breadcrumb: [{
                text: 'Editar noticia',
                active: true
            }],
            requiresAuth: true
        }
    }
]