import Vue from 'vue';
import VueRouter from 'vue-router';


// Libs
import { canNavigate } from '@/libs/acl/routeProtection';
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils';


// Routes
import home from './routes/home';
import login from './routes/authentication/login';
import account from './routes/account';
import booking from './routes/booking';
import miscellaneous from './routes/miscellaneous';
import coupon from './routes/coupon';
import doctor from './routes/doctor';
import survey from './routes/survey';
import notice from './routes/notice';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        ...home,
        ...booking,
        ...coupon,
        ...doctor,
        ...survey,
        ...notice,
        ...login,
        ...account,
        ...miscellaneous,
        {
            path: '*',
            name: 'error-404',
            component: () =>
                import ('@/views/error/Error404.vue'),
            meta: {
                layout: 'full'
            }
        }
    ]
});

router.beforeEach(async (to, _, next) => {
    
    const isLoggedIn = await isUserLoggedIn();

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({ name: 'login' });

        // If logged in => not authorized
        return next({ name: 'misc-not-authorized' });
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData();
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!isLoggedIn) return next({ name: 'login' });
        return next()
    }

    return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
        appLoading.style.display = 'none';
    }
});

export default router;