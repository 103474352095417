export default [{
        path: '/coupons',
        name: 'coupons',
        component: () =>
            import ('@/views/modules/coupons/coupons-list/CouponsList.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Cupones de descuento',
            breadcrumb: [{
                text: 'Lista de cupones de descuento',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/coupon/create',
        name: 'coupons-create',
        component: () =>
            import ('@/views/modules/coupons/coupons-store/CouponStore.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Cupones de descuento',
            breadcrumb: [{
                text: 'Nuevo cupón de descuento',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/coupon/:id/edit',
        name: 'coupons-edit',
        component: () =>
            import ('@/views/modules/coupons/coupons-edit/CouponsEdit.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Cupones de descuento',
            breadcrumb: [{
                text: 'Editar cupón de descuento',
                active: true
            }],
            requiresAuth: true
        }
    }
]