export default {
    // Endpoints
    loginEndpoint: '/auth/login',
    logoutEndpoint: '/auth/logout',
    userEnpoint: '/auth/user',

    // This will be prefixed in authorization header with token
    tokenType: 'Bearer',

    // Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken'
}
