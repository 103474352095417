export default [{
        path: '/doctors',
        name: 'doctors',
        component: () =>
            import ('@/views/modules/doctors/doctors-list/DoctorsList.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Doctores',
            breadcrumb: [{
                text: 'Lista de doctores',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/doctor/create',
        name: 'doctors-create',
        component: () =>
            import ('@/views/modules/doctors/doctors-store/DoctorStore.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Doctores',
            breadcrumb: [{
                text: 'Nuevo doctor',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/doctor/:id/edit',
        name: 'doctors-edit',
        component: () =>
            import ('@/views/modules/doctors/doctors-edit/DoctorsEdit.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Doctores',
            breadcrumb: [{
                text: 'Editar doctor',
                active: true
            }],
            requiresAuth: true
        }
    }
]