export default [{
        path: '/bookings',
        name: 'bookings',
        component: () =>
            import ('@/views/modules/bookings/bookings-list/BookingsList.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Reservas',
            breadcrumb: [{
                text: 'Lista de reservas',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/bookings/create',
        name: 'booking-create',
        component: () =>
            import ('@/views/modules/bookings/bookings-store/BookingsStore.vue'),
        meta: {
            pageTitle: 'Reservas',
            breadcrumb: [{
                text: 'Nueva Reserva',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/bookings/:id/edit',
        name: 'booking-edit',
        component: () =>
            import ('@/views/modules/bookings/bookings-edit/BookingsEdit.vue'),
        meta: {
            pageTitle: 'Reservas',
            breadcrumb: [{
                text: 'Editar Reserva',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/bookings/calendar',
        name: 'bookings-calendar',
        component: () =>
            import ('@/views/modules/bookings/bookings-view/BookingsCalendar.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Reservas',
            breadcrumb: [{
                text: 'Vista Calendario',
                active: true
            }],
            requiresAuth: true
        }
    }
]