export default [{
        path: '/surveys',
        name: 'surveys',
        component: () =>
            import ('@/views/modules/surveys/surveys-list/SurveysList.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Encuestas',
            breadcrumb: [{
                text: 'Lista de encuestas',
                active: true
            }],
            requiresAuth: true
        }
    }
]